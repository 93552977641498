import React, { } from "react"
import { Navigate } from "react-router-dom"

import { LINKS } from "utils/links"
import Login from "pages/Authentication/Login"
import UserProfile from "pages/Authentication/user-profile"
import StockManagement from "pages/StockManagement"
import EditProduct from "pages/StockManagement/EditProduct"
import OrderProcessing from "pages/OrderProcessing"
import ProductInformation from "pages/ProductInformation"
import NewProducts from "pages/NewProducts"
import NewPrices from "pages/NewPrices"
import Orders from "pages/OrderProcessing/listing"

const user = JSON.parse(localStorage.getItem("authUser"))
let superAdmin;
user?.roleType?.map((role) => {
  if (role.name == "Super Admin") {
    superAdmin = true;
  } else {
    superAdmin = false;
  }
})

const authProtectedRoutes = [

  // Stock Management
  { path: LINKS.stock_management, component: <StockManagement /> },
  { path: `${LINKS.stock_management}/edit/:id`, component: <EditProduct /> },

  // Order Processing
  { path: LINKS.orders, component: <Orders /> },
  { path: `${LINKS.orders}/:id`, component: <OrderProcessing /> },

  // Client Communication
  { path: LINKS.product_information, component: <ProductInformation /> },
  { path: LINKS.new_product, component: <NewProducts /> },
  { path: LINKS.product_prices, component: <NewPrices /> },

  { path: "/user-profile", component: <UserProfile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to={LINKS.stock_management} />,
  },
  {
    path: "*",
    component: <Navigate to={LINKS.stock_management} />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }
