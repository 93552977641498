import { gql } from '@apollo/client';

const GET_VARIATION_BY_VARIATION_ID = gql`
query GetVariationByVariationId($getVariationByVariationId: ID!) {
  GetVariationByVariationId(id: $getVariationByVariationId) {
    _id
    variation_id
    product_id
    sku
    height
    length
    weight
    width
  }
}
`

export {
  GET_VARIATION_BY_VARIATION_ID,
}
