import React from "react";
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import App from "./App"
import { AuthContextProvider } from "context/AuthContext"
import client from "./apollo-config"
import { store, persistor } from "./store"
import "./i18n"

const root = createRoot(document.getElementById("root"))

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
)

