import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from "reactstrap"
import { SHIPMENT_SUPPLIER } from "utils/constants"
import "./ShipmentConfirmation.scss"
import dpdImage from "../../../assets/images/deliveryPartner/dpd-logo-3.png"
import postNlImage from "../../../assets/images/deliveryPartner/postNl-log.png"

import { useLazyQuery } from "@apollo/client"
import { GET_VARIATION_BY_VARIATION_ID } from "graphql/queries/variation"
import { fontSize } from "@mui/system";

const keysToIncludeInAddress = [
  "Name",
  "Street",
  "PostalCode",
  "Place",
  "State",
  "Country",
]

const ShipmentConfirmationForDPD = (props, args) => {
  const {
    show,
    tittle,
    deliveryPartner,
    activeShipmentDetail = [],
    customerDetail = null,
    onConfirmClick,
    onCloseClick,
  } = props

  const [deliveryInfo, setDeliveryInformation] = useState({
    delivery_instruction: "",
  })

  const [packageInfo, setPackageInfo] = useState([])

  const [isWeightLess, setIsWeightLess] = useState(false)
  const [fetchVariation] = useLazyQuery(GET_VARIATION_BY_VARIATION_ID);

  useEffect(() => {
    const fetchVariationsAndSetPackageInfo = async () => {
      if (activeShipmentDetail?.length > 0) {
        // Use Promise.all to fetch all variations concurrently
        const updatedDetails = await Promise.all(
          activeShipmentDetail.map(async (item) => {
            const { data } = await fetchVariation({
              variables: { getVariationByVariationId: item.variation_id },
            });

            // Add fetched variation data to the item
            return {
              ...item,
              variation: { ...data.GetVariationByVariationId },
            };
          })
        );

        // Map through the updated details to construct package info
        const data = updatedDetails.map((product, i) => ({


          productName: product?.item?.name, // new added
          product_id: product?.product_id,
          supplier: "Cavetown B.V",
          pickup_instruction: null,
          quantity: product?.quantity,
          packages: Array.from(
            { length: product?.item?.packages ? product?.item?.packages : 1 },
            (v, i) => i + 1
          ).map((item, i) => ({
            Depth:
              product?.variation?.length?.toString() || product?.item?.packages_dimensions[i]?.length?.toString() || "",
            Height:
              product?.variation?.height?.toString() || product?.item?.packages_dimensions[i]?.height?.toString() || "",
            Width:
              product?.variation?.width?.toString() || product?.item?.packages_dimensions[i]?.width?.toString() || "",
            Weight:
              product?.variation?.weight?.toString() || product?.item?.packages_dimensions[i]?.weight?.toString() || "",
            ArticleWeight:
              product?.variation?.weight?.toString() || product?.item?.packages_dimensions[i]?.weight?.toString() || "",
            Price: product?.price || 0,
          })),
        }));

        const isWeightLess = data.some(product =>
          
          product.packages.some(pkg => parseFloat(pkg.Weight) < deliveryPartner === "DPD" ? 31.5 : 23)
        );
        if (isWeightLess) {
          setIsWeightLess(true);
        }
        setPackageInfo(data);
      }
    };

    // Call the async function inside useEffect
    fetchVariationsAndSetPackageInfo();
  }, [activeShipmentDetail, show]);

  const handlePackageInfo = (productId, packageIndex, newPackageData) => {

    if (newPackageData.Weight) {
      setIsWeightLess(parseFloat(newPackageData.Weight) < (deliveryPartner === "DPD" ? 31.5 : 23));
    }

    const calculateTotalWeight = (packages) => {
      return packages.reduce((total, pkg) => {
          return total + (parseFloat(pkg.Weight) || 0);
      }, 0);
    };

    setPackageInfo(prevProducts => {
      
      const updatedProducts = prevProducts.map(product =>
        product.product_id === productId
          ? {
            ...product,
            packages: product.packages.map((pkg, index) =>
              index === packageIndex ? { ...pkg, ...newPackageData } : pkg
            ),
          }
          : product
      );

      const totalWeight = updatedProducts.reduce((total, product) => {
        return total + calculateTotalWeight(product.packages);
      }, 0);

      setIsWeightLess(totalWeight <= (deliveryPartner === "DPD" ? 31.5 : 23));

      return updatedProducts;
    });
  }

  const updateSupplier = (productId, newSupplier) => {
    setPackageInfo(prevProducts =>
      prevProducts.map(product =>
        product.product_id === productId
          ? { ...product, supplier: newSupplier }
          : product
      )
    )
  }

  const saveData = () => {
    const payload = {
      deliveryInfo: deliveryInfo,
      package_info: packageInfo,
      deliveryPartner: deliveryPartner,
    }
    onConfirmClick(payload)
  }

  const closeModal = () => {
    setDeliveryInformation({
      delivery_instruction: "",
    })

    setPackageInfo([])
    onCloseClick()
  }

  const handleSubmit = e => {
    e.preventDefault()
    saveData()
    closeModal()
  }

  const createAddressString = (index, keys) => {
    const supplierAddress = SHIPMENT_SUPPLIER.find(
      o => o.value === packageInfo[index]?.supplier
    )?.address
    return keys
      .map(key => supplierAddress?.Address[key])
      .filter(value => value) // Filter out null or undefined values
      .join(", ")
  }

  const addMorePackage = (e, i, productId) => {
    e.preventDefault();
    e.stopPropagation();
    if (packageInfo != undefined && packageInfo.length > 0 && packageInfo[i] != undefined && packageInfo[i].packages != undefined) {
      let packages = packageInfo[i].packages
      packages.push({
        'Depth': '',
        'Height': '',
        'Width': '',
        'Weight': '',
        'ArticleWeight': '',
        'Price': 0
      })
      setPackageInfo((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === productId
            ? {
              ...product,
              packages: packages,
            }
            : product
        )
      );
    }
  }
  const deletePackage = (e, i, productId, package_index) => {
    e.preventDefault();
    e.stopPropagation();
    if (packageInfo != undefined && packageInfo.length > 0 && packageInfo[i] != undefined && packageInfo[i].packages != undefined) {
      let packages = [];
      for (const index in packageInfo[i].packages) {
        if (package_index != index) {
          let element = packageInfo[i].packages[index];
          packages.push(element)
        }
      }
      setPackageInfo((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === productId
            ? {
              ...product,
              packages: packages,
            }
            : product
        )
      );
    }
  }

  return (
    <div>
      <Modal
        className="shipment-confirmation"
        isOpen={show}
        toggle={() => closeModal()}
        centered={true}
        backdrop={true}
        size="xl"
        {...args}
      >
        <ModalHeader toggle={() => closeModal()} className="header sticky-top">
          <p className="delivery-partner mb-0">{tittle}</p>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={e => handleSubmit(e)}>
            <div className="d-flex align-items-start">
              <div className="div-details">
                <p className="delivery-partner">
                  Delivery Partner : {deliveryPartner}
                </p>
                <img
                  src={deliveryPartner === 'PostNL' ? postNlImage : dpdImage}
                  alt={deliveryPartner === 'PostNL' ? 'PostNl' : 'DPD'}
                  className="dpd-delivery-partner-image"
                />
              </div>
              <div className="customer-detail mt-2">
                <p className="fs-5 fw-bold">Customer Information</p>
                <p className="custom-p-spacing">{customerDetail?.name}</p>
                <p className="custom-p-spacing">{customerDetail?.address}</p>
                <p className="custom-p-spacing">{`${customerDetail?.country}, ${customerDetail?.city}`}</p>
                <p className="custom-p-spacing">{customerDetail?.telephone}</p>
                <p className="custom-p-spacing">
                  {customerDetail?.email?.split(":")[1]}
                </p>
              </div>
            </div>

            <div className="scroll-details">
              <Card className="main-card">
                {activeShipmentDetail?.map((product, i) => {
                  return (
                    <div className="product-section mt-3" key={i}>
                      <div className="product-info d-flex align-items-center justify-content-between">
                        <div>
                          <p className="title text-uppercase">Name</p>
                          <p className="fs-6 fw-normal">
                            {product?.item?.name}
                          </p>
                        </div>
                        <div>
                          <p className="title text-uppercase">Quantity</p>
                          <p className="text-center">{product?.quantity}</p>
                        </div>
                        <div>
                          <p className="title text-uppercase">Packages</p>
                          <p className="text-center">
                            {product?.item?.packages
                              ? product?.item?.packages
                              : 1}
                          </p>
                        </div>
                      </div>
                      <div className="package-info mt-3">
                        <div className="package-header">
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-10 d-flex align-items-center">
                              <span className="title title-width">DEPTH<i className="error">*</i></span>
                              <span className="title ms-3 title-width">HEIGHT<i className="error">*</i></span>
                              <span className="title ms-3 title-width">WIDTH<i className="error">*</i></span>
                              <span className="title ms-3 title-width">WEIGHT<i className="error">*</i> {!isWeightLess && <span className="message-warining" style={{ fontSize: 11, color: "red" }}>The maximum weight is {deliveryPartner === "DPD" ? "31.5" : "23"}kg</span>}</span>
                            </div>
                          </div>
                        </div>
                        {packageInfo?.length > 0 &&
                          packageInfo[i]?.packages?.map(
                            (pkg, package_index) => {
                              return (
                                <div key={package_index} className="row mt-2">
                                  <div className="col-md-2">
                                    <p>Package {package_index + 1}</p>
                                  </div>
                                  <div className="col-md-10 d-flex align-items-center">
                                    <input
                                      type="number"
                                      value={pkg.Depth}
                                      className="form-control element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Depth: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Height}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Height: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Width}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Width: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    <input
                                      type="number"
                                      value={pkg.Weight}
                                      className="form-control ms-3 element-width"
                                      onChange={e =>
                                        handlePackageInfo(
                                          product.product_id,
                                          package_index,
                                          { Weight: e.target.value }
                                        )
                                      }
                                      required
                                      min="1"
                                      step="any"
                                    />
                                    {
                                      package_index > 0 ? <button className='btn btn-primary btn-icon ml-2' onClick={(e) => deletePackage(e, i, product.product_id, package_index)}>
                                        <i className='fa fa-trash'></i></button> :
                                        <span />
                                    }
                                    {/* <input type="number" value={pkg.ArticleWeight} className='form-control ms-3 element-width' onChange={(e) => handlePackageInfo(product.product_id, package_index, { "ArticleWeight": e.target.value })} required min="1" step="any"/> */}
                                  </div>
                                </div>
                              )
                            }
                          )}
                        <div className='row'>
                          <div className='col-md-2'>
                          </div>
                          <div className='col-md-10 mt-2'>
                            <a onClick={(e) => addMorePackage(e, i, product.product_id)} className='color-blue'>Add More</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className="row supplier-info mt-3">
                  <div className="col-md-4">
                    <p className="title mb-1">Supplier</p>
                    <div className="d-flex">


                      <select
                        required
                        defaultValue={SHIPMENT_SUPPLIER.find(item => item.label === "Cavetown B.V")?.value}
                        className="form-select mb-2"
                        onChange={e =>
                          updateSupplier(
                            activeShipmentDetail[0].item.product_id,
                            e.target.value
                          )
                        }
                      >
                        {SHIPMENT_SUPPLIER.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <p className="title col-md-8 mt-4 mb-3 d-flex address">
                    {createAddressString(0, keysToIncludeInAddress)}
                  </p>

                </div>
              </Card>
            </div>

            <ModalFooter>
              <Button color="secondary" onClick={() => closeModal()}>
                Cancel
              </Button>{" "}
              <Button type="submit" color="primary" disabled={!isWeightLess}>
                Confim
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ShipmentConfirmationForDPD
